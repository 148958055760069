export const ApiRoutes = {
  partner: {
    current: "/current_partner",
  },
  company: {
    list: "/company",
    update: (COMPANY_ID: string) => `/company/${COMPANY_ID}`,
    init_information: (COMPANY_ID: string) =>
      `/company/${COMPANY_ID}/init_information`,
  },
  profile: {
    me: "/profile/me",
  },
  api_key: {
    list: (PROFILE_ID: string) => `/profile/${PROFILE_ID}/apikey`,
    detail: (PROFILE_ID: string, API_KEY: string) =>
      `/profile/${PROFILE_ID}/apikey/${API_KEY}`,
  },
  change_password: {
    list: "/profile/reset_password",
  },
  access_permission: {
    raw: `/access_permission/raw`,
  },
  signin: "/signin",
  refresh: "/token/refresh",
  signup: "/signup",
  verify_email: "/verify_email",
  resend_email: "/resend_email",
  forget_password: "/forget_password",
  reset_password: "/reset_password",
  registration_quetion: {
    detail_step: (step: string) => `/registration_quetion?step=${step}`,
  },
};

export const NextApiRoutes = (COMPANY_ID: string) => ({
  threads: {
    list: (CHAT_CHANNEL: string, ACCOUNT_ID: string) =>
      `${COMPANY_ID}/${CHAT_CHANNEL}/${ACCOUNT_ID}/threads`,
    messages: (CHAT_CHANNEL: string, ACCOUNT_ID: string, THREAD_ID: string) =>
      `${COMPANY_ID}/${CHAT_CHANNEL}/${ACCOUNT_ID}/threads/${THREAD_ID}/messages`,
    detail: (CHAT_CHANNEL: string, ACCOUNT_ID: string, THREAD_ID: string) =>
      `${COMPANY_ID}/${CHAT_CHANNEL}/${ACCOUNT_ID}/threads/${THREAD_ID}`,
  },
});

export const ApiRoutesCompany = (COMPANY_ID: string) => ({
  auth_group: {
    me: `/company/${COMPANY_ID}/auth_group/me`,
    detail: (AUTH_GROUP_ID: string) =>
      `/company/${COMPANY_ID}/auth_group/${AUTH_GROUP_ID}`,
    list: `/company/${COMPANY_ID}/auth_group`,
  },
  permission_set: {
    default: `/company/${COMPANY_ID}/permission_set/default`,
    list: `/company/${COMPANY_ID}/permission_set`,
    detail: (PERMISSION_SET_ID: string) =>
      `/company/${COMPANY_ID}/permission_set/${PERMISSION_SET_ID}`,
  },
  companyprofile: {
    list: `/company/${COMPANY_ID}/companyprofile`,
    me: `/company/${COMPANY_ID}/companyprofile/me`,
    detail: (PROFILE_COMPANY_ID: string) =>
      `/company/${COMPANY_ID}/companyprofile/${PROFILE_COMPANY_ID}`,
  },

  current_company: `/company/${COMPANY_ID}`,
  agent: {
    me: `/company/${COMPANY_ID}/agent/me`,
  },
  employee: {
    list: `/company/${COMPANY_ID}/profile`,
  },
  sms_campaign_zalo: {
    list: `/company/${COMPANY_ID}/sms_campaign`,
    create: `/company/${COMPANY_ID}/sms_campaign`,
    delete: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}`,
    detail: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}`,
    run_campaign: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}/run_campaign`,
    upload_from_file: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}/import_excel`,
    config: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}/settings`,
    send_test_message: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}/send_test`,
    export_template: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}/export_template_xlsx`,
    upload_from_customer_group: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}/import_groups`,
    upload_from_other_campaign: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}/import_other_campaign`,
    preview_campaign: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}/preview_campaign`,
    report: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}/report`,
    detail_report: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}/history`,
    export_campaign: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}/export_excel`,
    integration_config: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}/integration`,
    run_campaign_cs: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}`,
    list_option: (AUTOCALL_ID: string) =>
      `/company/${COMPANY_ID}/call_campaign/${AUTOCALL_ID}/customer_report/filter_columns`,
  },

  group: {
    list: `/company/${COMPANY_ID}/group`,
    detail: (PROFILE_GROUP_ID: string) =>
      `/company/${COMPANY_ID}/group/${PROFILE_GROUP_ID}`,
  },
  profile: {
    list: `/company/${COMPANY_ID}/profilev2`,
    profilev3: `/company/${COMPANY_ID}/profilev3`,
    companyprofileraw: `/company/${COMPANY_ID}/companyprofileraw`,
    detail: (PROFILE_ID: string) =>
      `/company/${COMPANY_ID}/profilev2/${PROFILE_ID}`,
  },
  user: {
    details: `/company/${COMPANY_ID}/detail_user`,
    detail: (USER_ID: string) =>
      `/company/${COMPANY_ID}/detail_user/${USER_ID}`,
  },
  view: {
    list_view: (MODLE_NAME: string) =>
      `/company/${COMPANY_ID}/view?app_model_name=${MODLE_NAME}`,
    get_view_order: (MODLE_NAME: string) =>
      `/company/${COMPANY_ID}/view/get_view_order?app_model_name=${MODLE_NAME}`,
    update_view_order: (MODLE_NAME: string) =>
      `/company/${COMPANY_ID}/view/update_view_order?app_model_name=${MODLE_NAME}`,
    list_view_product: `/company/${COMPANY_ID}/view?app_model_name=crmproduct`,
    create_view: `/company/${COMPANY_ID}/view`,
    update_view: (VIEW_ID: string) => `/company/${COMPANY_ID}/view/${VIEW_ID}`,
    delete_view: (VIEW_ID: string) => `/company/${COMPANY_ID}/view/${VIEW_ID}`,
    copy_view: (VIEW_ID: string) =>
      `/company/${COMPANY_ID}/view/${VIEW_ID}/copy`,
    view_filter_group: (VIEW_ID: string) =>
      `/company/${COMPANY_ID}/view/${VIEW_ID}/filter_group`,
    update_view_filter_group: (VIEW_ID: string) =>
      `/company/${COMPANY_ID}/view/${VIEW_ID}/update_filter_group`,
    grid_view_options: (VIEW_ID: string) =>
      `/company/${COMPANY_ID}/view/${VIEW_ID}/grid_field_options`,
    kanban_view_options: (VIEW_ID: string) =>
      `/company/${COMPANY_ID}/view/${VIEW_ID}/kanban_field_options`,
    kanban_group_field_options: (MODLE_NAME: string) =>
      `/company/${COMPANY_ID}/view/kanban_group_field_options?app_model_name=${MODLE_NAME}`,
  },
  contact: {
    filter_contact: `/company/${COMPANY_ID}/contact/advance_filter`,
    create_contact: `/company/${COMPANY_ID}/contact`,
    relate_object: `/company/${COMPANY_ID}/contact/relate_object`,
    update_contact: (CONTACT_ID: string) =>
      `/company/${COMPANY_ID}/contact/${CONTACT_ID}`,
    import_contact_file: `/company/${COMPANY_ID}/contact/import_excel`,
    export_contact_excel: `/company/${COMPANY_ID}/contact/export_excel`,
    delete_list: `/company/${COMPANY_ID}/contact/delete_list`,
    update_list: `/company/${COMPANY_ID}/contact/update_list`,
    assign_list: `/company/${COMPANY_ID}/contact/assign_list`,
    contact_info_detail: (CONTACT_ID: string) =>
      `/company/${COMPANY_ID}/contact/${CONTACT_ID}`,
    list_activity: `/company/${COMPANY_ID}/pghistory`,
    connect: `/company/${COMPANY_ID}/thread`,
    get_detail_by_thread_id: (THREAD_ID: string) =>
      `/company/${COMPANY_ID}/thread/${THREAD_ID}/thread_mongo_id`,
    disconnect: (THREAD_ID: string) =>
      `/company/${COMPANY_ID}/thread/${THREAD_ID}`,
  },
  property: {
    get_all_property: (MODLE_NAME: string) =>
      `/company/${COMPANY_ID}/property/all?app_model_name=${MODLE_NAME}`,
    list_option_value: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option`,
    export_template: `/company/${COMPANY_ID}/property/export_template_xlsx`,
  },

  sms_official_account: {
    list: `/company/${COMPANY_ID}/sms_official_account`,
    detail: (SMS_OFFICIAL_ACCOUNT_ID: string) =>
      `/company/${COMPANY_ID}/sms_official_account/${SMS_OFFICIAL_ACCOUNT_ID}`,
  },
  zalo_oa: {
    exchange_token: `/company/${COMPANY_ID}/zalooa/exchange_token`,
    list: `/company/${COMPANY_ID}/zalooa`,
    detail: (ZALOOA_ID: string) => `/company/${COMPANY_ID}/zalooa/${ZALOOA_ID}`,
    send_message: (ZALOOA_ID: string) =>
      `/company/${COMPANY_ID}/zalooa/${ZALOOA_ID}/send_message`,
    sync_message: (ZALOOA_ID: string) =>
      `/company/${COMPANY_ID}/zalooa/${ZALOOA_ID}/sync_message`,
    uploadFile: (ZALOOA_ID: string) =>
      `/company/${COMPANY_ID}/zalooa/${ZALOOA_ID}/upload_file`,
    uploadImage: (ZALOOA_ID: string) =>
      `/company/${COMPANY_ID}/zalooa/${ZALOOA_ID}/upload_image`,
    send_anonymous_message: (ZALOOA_ID: string) =>
      `/company/${COMPANY_ID}/zalooa/${ZALOOA_ID}/send_anonymous_message`,
  },
  zalo_account: {
    list: `/company/${COMPANY_ID}/zaloaccount`,
    detail: (ZALOACCOUNT_ID: string) =>
      `/company/${COMPANY_ID}/zaloaccount/${ZALOACCOUNT_ID}`,
  },
  tax: {
    detail: `/company/${COMPANY_ID}/tax`,
  },
  customer: {
    list: (SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/sms_campaign/${SMS_CAMPAIGN_ID}/customer`,
  },
  template_OA: {
    list: `/company/${COMPANY_ID}/sms_template`,
    detail: (TEMPLATE_ID: string) =>
      `/company/${COMPANY_ID}/sms_template/${TEMPLATE_ID}`,
  },
  customer_group: {
    list: `/company/${COMPANY_ID}/customer_group`,
    listFields: (CUSTOMER_GROUP_ID: string) =>
      `/company/${COMPANY_ID}/customer_group/${CUSTOMER_GROUP_ID}/list_fields`,
  },
  customer_of_group: {
    list: (CUSTOMER_GROUP_ID: string) =>
      `/company/${COMPANY_ID}/customer_group/${CUSTOMER_GROUP_ID}/customer`,
  },
  call_campaign: {
    list: `/company/${COMPANY_ID}/call_campaign`,
  },
  demo_landing_page: (COMPANY_ID_CUSTOM: string, CALL_CAMPAIGN_ID: string) =>
    `/company/${COMPANY_ID_CUSTOM}/call_campaign/${CALL_CAMPAIGN_ID}/customer_demo`,
  agent_campaign: {
    list: `/company/${COMPANY_ID}/agent_call_campaign`,
  },
  call_campaign_customer: {
    list: (CALL_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer`,
    list_field_campaign: (CALL_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/list_fields`,
  },
  job: {
    job_crm: (JOB_ID: string) => `/job_crm/${JOB_ID}`,
  },
  setting_callcenter: {
    list: `/company/${COMPANY_ID}/callcenterhotline`,
    get: `/company/${COMPANY_ID}/callcentersetting`,
  },
  record: {
    list: `/company/${COMPANY_ID}/callcenterrecord`,
    detail: (RECORD_ID: string) =>
      `/company/${COMPANY_ID}/callcenterrecord/${RECORD_ID}`,
  },
  contact_property: {
    list: `/company/${COMPANY_ID}/property`,
    update: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}`,
    detail: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}`,
    list_input_type: `/company/${COMPANY_ID}/property/type_option`,
    create: `company/${COMPANY_ID}/property`,
    delete: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}`,
    list_option: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option`,
    create_option: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option`,
    option_detail: (PROPERTY_ID: string, OPTION_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option/${OPTION_ID}`,
    update_option: (PROPERTY_ID: string, OPTION_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option/${OPTION_ID}`,
    delete_option: (PROPERTY_ID: string, OPTION_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option/${OPTION_ID}`,
  },
  enterprise: {
    enterprise: `/company/${COMPANY_ID}/enterprise`,
    filter_enterprise: `/company/${COMPANY_ID}/enterprise/advance_filter`,
    create_enterprise: `/company/${COMPANY_ID}/enterprise`,
    enterprise_detail: (ENTERPRISE_ID: string) =>
      `/company/${COMPANY_ID}/enterprise/${ENTERPRISE_ID}`,
    export_enterprise_excel: `/company/${COMPANY_ID}/enterprise/export_excel`,
    delete_list: `/company/${COMPANY_ID}/enterprise/delete_list`,
    import_file: `/company/${COMPANY_ID}/enterprise/import_excel`,
    update_list: `/company/${COMPANY_ID}/enterprise/update_list`,
  },
  agent_call: {
    list: `/company/${COMPANY_ID}/agent_call_campaign`,
  },
  agent_call_customer: {
    list: (AGENT_CALL_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CALL_CAMPAIGN_ID}/customer`,
    list_fields: (AGENT_CALL_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CALL_CAMPAIGN_ID}/list_fields`,
  },
  products: {
    list: `/company/${COMPANY_ID}/crm_product`,
    detail: (PRODUCT_ID: string) =>
      `/company/${COMPANY_ID}/crm_product/${PRODUCT_ID}`,
    filter_product: `/company/${COMPANY_ID}/crm_product/advance_filter`,
    delete: `/company/${COMPANY_ID}/crm_product/delete_list`,
    update_list: `/company/${COMPANY_ID}/crm_product/update_list`,
  },
  product_property: {
    list: `/company/${COMPANY_ID}/property`,
    update: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}`,
    detail: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}`,
    list_input_type: `/company/${COMPANY_ID}/property/type_option`,
    create: `company/${COMPANY_ID}/property`,
    delete: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}`,
    list_option: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option`,
    create_option: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option`,
    option_detail: (PROPERTY_ID: string, OPTION_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option/${OPTION_ID}`,
    update_option: (PROPERTY_ID: string, OPTION_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option/${OPTION_ID}`,
    delete_option: (PROPERTY_ID: string, OPTION_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option/${OPTION_ID}`,
  },

  crm_file: {
    list: `/company/${COMPANY_ID}/file`,
    delete: (FILE_ID: string) => `/company/${COMPANY_ID}/file/${FILE_ID}`,
  },
  list: {
    list: `/company/${COMPANY_ID}/list`,
    view_list_object: `/company/${COMPANY_ID}/list/options`,
    filter_list: `/company/${COMPANY_ID}/list/advance_filter`,
    list_setting_view: (LIST_OBJECT_ID: string) =>
      `/company/${COMPANY_ID}/list/${LIST_OBJECT_ID}/setting/view`,
    list_object: (LIST_OBJECT_ID: string) =>
      `/company/${COMPANY_ID}/list/${LIST_OBJECT_ID}`,
    assign_setting: (LIST_OBJECT_ID: string) =>
      `/company/${COMPANY_ID}/list/${LIST_OBJECT_ID}/assign`,
    objects: (LIST_OBJECT_ID: string) =>
      `/company/${COMPANY_ID}/list/${LIST_OBJECT_ID}/setting`,
    filter_object: (LIST_OBJECT_ID: string) =>
      `/company/${COMPANY_ID}/list/${LIST_OBJECT_ID}/setting/advance_filter`,
    save_filter_object: (LIST_OBJECT_ID: string) =>
      `/company/${COMPANY_ID}/list/${LIST_OBJECT_ID}/setting/save_filter`,
    remove_contacts: (LIST_OBJECT_ID: string) =>
      `/company/${COMPANY_ID}/list/${LIST_OBJECT_ID}/remove_contacts`,
  },
  device: {
    create: `/device`,
  },
  notification: {
    list: `/notification`,
    update: (NOTIFICATION_ID: number) => `/notification/${NOTIFICATION_ID}`,
    read_all: `/notification/read_all`,
    view_notification: `notification/view_notification`,
    quantity_notification: `/notification/quantity_notification`,
  },
  facebook_pages: {
    list: `/company/${COMPANY_ID}/facebookpage`,
    detail: (FBPAGE_UUID: string) =>
      `/company/${COMPANY_ID}/facebookpage/${FBPAGE_UUID}`,
    exchange_token: `/company/${COMPANY_ID}/facebookpage/exchange_token`,
    send_message: (FBPAGE_UUID: string) =>
      `/company/${COMPANY_ID}/facebookpage/${FBPAGE_UUID}/send_message`,
    sync_messages: (FBPAGE_UUID: string) =>
      `/company/${COMPANY_ID}/facebookpage/${FBPAGE_UUID}/sync_message`,
  },
  note_contact: {
    create: (CONTACT_ID: string) =>
      `/company/${COMPANY_ID}/contact/${CONTACT_ID}/note`,
    list: (CONTACT_ID: string) =>
      `/company/${COMPANY_ID}/contact/${CONTACT_ID}/note`,
    update: (CONTACT_ID: string, NOTE_ID: number) =>
      `/company/${COMPANY_ID}/contact/${CONTACT_ID}/note/${NOTE_ID}`,
  },
  note_enterprise: {
    create: (ENTERPRISE_ID: string) =>
      `/company/${COMPANY_ID}/enterprise/${ENTERPRISE_ID}/note`,
    list: (CONTACT_ID: string) =>
      `/company/${COMPANY_ID}/enterprise/${CONTACT_ID}/note`,
    update: (CONTACT_ID: string, NOTE_ID: number) =>
      `/company/${COMPANY_ID}/enterprise/${CONTACT_ID}/note/${NOTE_ID}`,
  },
  note_deal: {
    create: (DEAL_ID: string) => `/company/${COMPANY_ID}/deal/${DEAL_ID}/note`,
    list: (DEAL_ID: string) => `/company/${COMPANY_ID}/deal/${DEAL_ID}/note`,
    update: (DEAL_ID: string, NOTE_ID: number) =>
      `/company/${COMPANY_ID}/deal/${DEAL_ID}/note/${NOTE_ID}`,
  },
  params: {
    post: `/company/${COMPANY_ID}/format/params`,
  },

  live_chat_notification: {
    get: `/company/${COMPANY_ID}/livechatnotification`,
    assign: (LIVECHAT_NOTIFICATION_ID: string) =>
      `/company/${COMPANY_ID}/livechatnotification/${LIVECHAT_NOTIFICATION_ID}`,
  },
  email: {
    list_template: `/company/${COMPANY_ID}/mailtemplate`,
    detail_template: (TEMPLATE_ID: string) =>
      `/company/${COMPANY_ID}/mailtemplate/${TEMPLATE_ID}`,
    list_mailconfig: `/company/${COMPANY_ID}/mailconfig`,
    send_mail_contact: (EMAIL_CONFIG_ID: string) =>
      `/company/${COMPANY_ID}/mailconfig/${EMAIL_CONFIG_ID}/send_mail_contact`,
    detail_mailconfig: (EMAIL_CONFIG_ID: string) =>
      `/company/${COMPANY_ID}/mailconfig/${EMAIL_CONFIG_ID}`,
  },
  telesales_campaigns: {
    list: `/company/${COMPANY_ID}/telesale_campaign`,
    create: `/company/${COMPANY_ID}/telesale_campaign`,
    update: (TELESALES_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALES_ID}`,
    delete: (TELESALES_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALES_ID}`,
    getOne: (TELESALES_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALES_ID}`,
    set_config: (TELESALES_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALES_ID}/settings`,
    init_time_condition: `company/${COMPANY_ID}/time_condition`,
    update_time_condition: (TIME_CONDITION_ID: string) =>
      `/company/${COMPANY_ID}/time_condition/${TIME_CONDITION_ID}`,
    init_time_range: `/company/${COMPANY_ID}/time_range_condition`,
    list_hotline: `/company/${COMPANY_ID}/hotline`,
    get_config: (TELESALES_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALES_ID}/settings`,
    list_report_form: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/report_forms`,
    create_report_form: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/report_forms`,
    delete_report_form: (
      TELESALE_CAMPAIGN_ID: string,
      TELESALE_CAMPAIGN_REPORT_FORM_ID: string
    ) => `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/report_forms/${TELESALE_CAMPAIGN_REPORT_FORM_ID}
    `,
    update_report_form: (
      TELESALE_CAMPAIGN_ID: string,
      TELESALE_CAMPAIGN_REPORT_FORM_ID: string
    ) => `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/report_forms/${TELESALE_CAMPAIGN_REPORT_FORM_ID}
    `,
    list_scenario: `/call_agent_scenario_sample`,
    create_call_agent_scenario: `/company/${COMPANY_ID}/call_agent_scenario`,
    update_call_agent_scenario: (CALL_AGENT_SCENARIO_ID: string) =>
      `/company/${COMPANY_ID}/call_agent_scenario/${CALL_AGENT_SCENARIO_ID}`,
    create_telesales_campaign_employee: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/employees/bulk_create`,
    list_telesales_campaign_employee: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/employees`,
    delete_telesales_campaign_employees: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/employees`,
    export_template_excel: `/company/${COMPANY_ID}/telesale_campaign/export_template_xlsx`,
    upload_from_file: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/import_excel`,
    list_total_customer_campaign: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/customer`,
    list_upload_history: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/upload_data_history`,
    upload_from_customer_group: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/import_list_object`,
    overview: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/overview`,
    overview_report_customer: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/report`,
    overview_call_report: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/report/report_call`,
    pop_customer: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/customer/pop`,
    update_request: (TELESALE_CAMPAIGN_ID: string, REQUEST_ID: number) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/request/${REQUEST_ID}`,
    get_customer_report: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/customer_report`,
    update_customer_telesales_campaign: (
      TELESALE_CAMPAIGN_ID: string,
      TELESALE_CAMPAIGN_CUSTOMER_ID: string
    ) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/customer/${TELESALE_CAMPAIGN_CUSTOMER_ID}`,
    get_list_customer_labels: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/label`,
    add_customer_labels: (TELESALE_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/label`,
    delete_customer_labels: (TELESALE_CAMPAIGN_ID: string, LABEL_ID: string) =>
      `/company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/label/${LABEL_ID}`,
    list_record: `/company/${COMPANY_ID}/telesale_campaign/record`,
    add_scenario_company_sample: `/company/${COMPANY_ID}/call_agent_scenario_company_sample`,
    update_telesale_campaign_employee: (
      employeeId: string,
      TELESALE_CAMPAIGN_ID: string
    ) =>
      `company/${COMPANY_ID}/telesale_campaign/${TELESALE_CAMPAIGN_ID}/employees/${employeeId}`,
  },
  order: {
    advance_filter: `/company/${COMPANY_ID}/crm_order/advance_filter`,
    delete_list: `/company/${COMPANY_ID}/crm_order/delete_list`,
    order_details: (ORDER_ID: string) =>
      `/company/${COMPANY_ID}/crm_order/${ORDER_ID}`,
    create_advance: `/company/${COMPANY_ID}/crm_order/create_advance`,
    update_advance: (ORDER_ID: string) =>
      `/company/${COMPANY_ID}/crm_order/${ORDER_ID}/update_advance`,
  },
  deal_property: {
    list: `/company/${COMPANY_ID}/property`,
    update: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}`,
    detail: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}`,
    list_input_type: `/company/${COMPANY_ID}/property/type_option`,
    create: `company/${COMPANY_ID}/property`,
    delete: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}`,
    list_option: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option`,
    create_option: (PROPERTY_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option`,
    option_detail: (PROPERTY_ID: string, OPTION_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option/${OPTION_ID}`,
    update_option: (PROPERTY_ID: string, OPTION_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option/${OPTION_ID}`,
    delete_option: (PROPERTY_ID: string, OPTION_ID: string) =>
      `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option/${OPTION_ID}`,
  },
  deal_config: {
    get: `/company/${COMPANY_ID}/deal_configuration`,
    update: (CONFIGURATION_ID: string) =>
      `/company/${COMPANY_ID}/deal_configuration/${CONFIGURATION_ID}`,
  },
  deal: {
    filter_deal: `/company/${COMPANY_ID}/deal/advance_filter`,
    create_deal: `/company/${COMPANY_ID}/deal`,
    update_deal: (DEAL_ID: string) => `/company/${COMPANY_ID}/deal/${DEAL_ID}`,
    deal_detail: (DEAL_ID: string) => `/company/${COMPANY_ID}/deal/${DEAL_ID}`,
    delete_list: `/company/${COMPANY_ID}/deal/delete_list`,
    update_list: `/company/${COMPANY_ID}/deal/update_list`,
    assign_list: `/company/${COMPANY_ID}/deal/assign_list`,
    export_deal: `/company/${COMPANY_ID}/deal/export_excel`,
  },
  deleted_telesales_campaigns: {
    list: `/company/${COMPANY_ID}/telesale_campaign/deleted`,
  },
});
